import "../ProductSlider/HomeProduct.css";
import "../../Css/ProductCard.css";
import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

import imageUrl1 from "../../Images/Product/demoproduct1.webp";
import imageUrl2 from "../../Images/Product/productimage11.webp";
import imageUrl3 from "../../Images/Product/Producimage2.webp";
import imageUrl4 from "../../Images/Product/Producimage21.webp";
import imageUrl5 from "../../Images/Product/Producimage3.webp";
import imageUrl6 from "../../Images/Product/Producimage31.webp";
import imageUrl7 from "../../Images/Product/Producimage4.webp";
import imageUrl8 from "../../Images/Product/Producimage41.webp";
import imageUrl9 from "../../Images/Product/productimage5.webp";
import imageUrl10 from "../../Images/Product/productimage51.webp";
import imageUrl11 from "../../Images/Product/productimage6.webp";
import imageUrl12 from "../../Images/Product/productimage61.webp";

import { FaStar } from "react-icons/fa";
import { get } from "../../services/ApiRouting";
import { Link } from "react-router-dom";
const HomeProduct = () => {

    const [menproduct, setMenProduct] = useState(null);
    const fetchData = async () => {
        try {
            const response = await get('/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1');
            console.log("homemenproduct", response.list);
            setMenProduct(response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <section className='Home-product-section-2'>
                <div className='Home-product-head-con'>
                    <div className='Home-product-head-title-con'>
                        <h2>Just Dropped</h2>
                    </div>
                    <div className='Home-Product-select-tabs-con'>
                        <div className='Home-Product-select-tabs'>
                            <div className='Home-Product-select-tab'>
                                <h3>Women</h3>
                            </div>
                            <div className='Home-Product-select-tab'>
                                <h3>Men</h3>
                            </div>
                            <div className='Home-Product-select-tab'>
                                <h3>Accessories</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Home-Product-select-card-section-con">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={1}
                        // centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 1,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 1,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 1,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper"
                    >
                        {
                            menproduct && menproduct.map((product, productIndex) => (
                                product.colors.map((colorItem, colorIndex) => (
                                    <>
                                        <SwiperSlide>
                                            <div className="product-card-main-con" key={`${productIndex}-${colorIndex}`}>
                                                <Link to={`${product.slug}`}>
                                                    <div className="product-card-body-con">
                                                        <div className="product-card-Image-con product-item__image double__image">
                                                            <a>
                                                                <div className="product-item__bg" style={{
                                                                    backgroundImage: `url(${colorItem.image[0]?.images})`,
                                                                    // height: '500px',  // Specify the height
                                                                    // width: '100%',    // Specify the width
                                                                    // backgroundSize: 'cover',   // Cover the entire area of the component
                                                                    // backgroundPosition: 'center' // Center the background image
                                                                }}></div>
                                                                <div className="product-item__bg__under " style={{
                                                                    backgroundImage: `url(${colorItem.image[1]?.images})`,
                                                                    // height: '500px',  // Specify the height
                                                                    // width: '100%',    // Specify the width
                                                                    // backgroundSize: 'cover',   // Cover the entire area of the component
                                                                    // backgroundPosition: 'center' // Center the background image
                                                                }}></div>
                                                            </a>
                                                            <div className="slideup is-not-relative">
                                                                <button
                                                                    type="button"
                                                                    className="caps"
                                                                    data-focus-element=""
                                                                    data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                                    data-collection-handle="primary"
                                                                    tabIndex={0}
                                                                >
                                                                    <span>Quick add</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                        <div className="Product-card-details-con">
                                                            <div className="Product-card-details">
                                                                <div className="product-card-product-name">
                                                                    <h4>{product.product_name}</h4>
                                                                </div>
                                                                <div className="Product-card-product-review">
                                                                    <div className="Product-card-product-review-star">
                                                                        <FaStar />
                                                                        <FaStar />
                                                                        <FaStar />
                                                                        <FaStar />
                                                                        <FaStar />
                                                                    </div>
                                                                    <div className="Product-card-product-review-text">
                                                                        <h5>1 Review</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="Product-card-product-review-price">
                                                                    <h6>Rs. {colorItem.product_price} /-</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    </>
                                ))
                            ))
                        }
                        {/* <SwiperSlide>
                            <div className="product-card-main-con">
                                <div className="product-card-body-con">
                                    <div className="product-card-Image-con product-item__image double__image">
                                        <a>
                                            <div className="product-item__bg" style={{
                                                backgroundImage: `url(${imageUrl3})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                            <div className="product-item__bg__under " style={{
                                                backgroundImage: `url(${imageUrl4})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                        </a>
                                        <div className="slideup is-not-relative">
                                            <button
                                                type="button"
                                                className="caps"
                                                data-focus-element=""
                                                data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                data-collection-handle="primary"
                                                tabIndex={0}
                                            >
                                                <span>Quick add</span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="Product-card-details-con">
                                        <div className="Product-card-details">
                                            <div className="product-card-product-name">
                                                <h4>1 Five x Memeland 'Finger' Tee </h4>
                                            </div>
                                            <div className="Product-card-product-review">
                                                <div className="Product-card-product-review-star">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </div>
                                                <div className="Product-card-product-review-text">
                                                    <h5>1 Review</h5>
                                                </div>
                                            </div>
                                            <div className="Product-card-product-review-price">
                                                <h6>Rs. 7,800.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="product-card-main-con">
                                <div className="product-card-body-con">
                                    <div className="product-card-Image-con product-item__image double__image">
                                        <a>
                                            <div className="product-item__bg" style={{
                                                backgroundImage: `url(${imageUrl5})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                            <div className="product-item__bg__under " style={{
                                                backgroundImage: `url(${imageUrl6})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                        </a>
                                        <div className="slideup is-not-relative">
                                            <button
                                                type="button"
                                                className="caps"
                                                data-focus-element=""
                                                data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                data-collection-handle="primary"
                                                tabIndex={0}
                                            >
                                                <span>Quick add</span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="Product-card-details-con">
                                        <div className="Product-card-details">
                                            <div className="product-card-product-name">
                                                <h4>1 Five x Memeland 'Finger' Tee </h4>
                                            </div>
                                            <div className="Product-card-product-review">
                                                <div className="Product-card-product-review-star">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </div>
                                                <div className="Product-card-product-review-text">
                                                    <h5>1 Review</h5>
                                                </div>
                                            </div>
                                            <div className="Product-card-product-review-price">
                                                <h6>Rs. 7,800.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="product-card-main-con">
                                <div className="product-card-body-con">
                                    <div className="product-card-Image-con product-item__image double__image">
                                        <a>
                                            <div className="product-item__bg" style={{
                                                backgroundImage: `url(${imageUrl7})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                            <div className="product-item__bg__under " style={{
                                                backgroundImage: `url(${imageUrl8})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                        </a>
                                        <div className="slideup is-not-relative">
                                            <button
                                                type="button"
                                                className="caps"
                                                data-focus-element=""
                                                data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                data-collection-handle="primary"
                                                tabIndex={0}
                                            >
                                                <span>Quick add</span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="Product-card-details-con">
                                        <div className="Product-card-details">
                                            <div className="product-card-product-name">
                                                <h4>1 Five x Memeland 'Finger' Tee </h4>
                                            </div>
                                            <div className="Product-card-product-review">
                                                <div className="Product-card-product-review-star">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </div>
                                                <div className="Product-card-product-review-text">
                                                    <h5>1 Review</h5>
                                                </div>
                                            </div>
                                            <div className="Product-card-product-review-price">
                                                <h6>Rs. 7,800.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="product-card-main-con">
                                <div className="product-card-body-con">
                                    <div className="product-card-Image-con product-item__image double__image">
                                        <a>
                                            <div className="product-item__bg" style={{
                                                backgroundImage: `url(${imageUrl9})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                            <div className="product-item__bg__under " style={{
                                                backgroundImage: `url(${imageUrl10})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                        </a>
                                        <div className="slideup is-not-relative">
                                            <button
                                                type="button"
                                                className="caps"
                                                data-focus-element=""
                                                data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                data-collection-handle="primary"
                                                tabIndex={0}
                                            >
                                                <span>Quick add</span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="Product-card-details-con">
                                        <div className="Product-card-details">
                                            <div className="product-card-product-name">
                                                <h4>1 Five x Memeland 'Finger' Tee </h4>
                                            </div>
                                            <div className="Product-card-product-review">
                                                <div className="Product-card-product-review-star">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </div>
                                                <div className="Product-card-product-review-text">
                                                    <h5>1 Review</h5>
                                                </div>
                                            </div>
                                            <div className="Product-card-product-review-price">
                                                <h6>Rs. 7,800.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="product-card-main-con">
                                <div className="product-card-body-con">
                                    <div className="product-card-Image-con product-item__image double__image">
                                        <a>
                                            <div className="product-item__bg" style={{
                                                backgroundImage: `url(${imageUrl11})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                            <div className="product-item__bg__under " style={{
                                                backgroundImage: `url(${imageUrl12})`,
                                                // height: '500px',  // Specify the height
                                                // width: '100%',    // Specify the width
                                                // backgroundSize: 'cover',   // Cover the entire area of the component
                                                // backgroundPosition: 'center' // Center the background image
                                            }}></div>
                                        </a>
                                        <div className="slideup is-not-relative">
                                            <button
                                                type="button"
                                                className="caps"
                                                data-focus-element=""
                                                data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                data-collection-handle="primary"
                                                tabIndex={0}
                                            >
                                                <span>Quick add</span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="Product-card-details-con">
                                        <div className="Product-card-details">
                                            <div className="product-card-product-name">
                                                <h4>1 Five x Memeland 'Finger' Tee </h4>
                                            </div>
                                            <div className="Product-card-product-review">
                                                <div className="Product-card-product-review-star">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </div>
                                                <div className="Product-card-product-review-text">
                                                    <h5>1 Review</h5>
                                                </div>
                                            </div>
                                            <div className="Product-card-product-review-price">
                                                <h6>Rs. 7,800.00</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-one">
                <div className="Home-Section-3-main-con">
                    <div className="Home-Section-3-deatils-con">
                        <h3>SHOP OUR LATEST COLLABORATION</h3>
                        <h4 >1 Five x Keith Haring</h4>
                    </div>
                    <div className="Home-Section-3-btn-con">
                        <a>
                            <button>
                                view products
                            </button>
                        </a>

                    </div>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-second">
                <div className="Home-Section-3-main-con">
                    <div className="Home-Section-3-deatils-con">
                        <h3>1 Five HOME</h3>
                        <div className="Home-Section-3-tagline">
                            <h4 className="Home-Section-3-deatils-tagline-con">All shades of black available.</h4>
                        </div>
                    </div>
                    <div className="Home-Section-3-btn-con">
                        <a>
                            <button>
                                see the collection
                            </button>
                        </a>

                    </div>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-third">
                <div className="Home-Section-3-main-con">
                    <div className="Home-Section-3-deatils-con">
                        <h4>Shades of 1 Five</h4>
                    </div>
                    <div className="Home-Section-3-btn-con">
                        <a>
                            <button>
                                view products
                            </button>
                        </a>

                    </div>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-fourth">
                <div className="Home-Section-3-main-con">
                    <div className="Home-Section-3-deatils-con">
                        <h3>WHTE</h3>
                        <div className="Home-Section-3-tagline">
                            <h4 className="Home-Section-3-deatils-tagline-con">Welcome to the bright side.</h4>
                        </div>
                    </div>
                    <div className="Home-Section-3-btn-con">
                        <a>
                            <button>
                                shop men
                            </button>
                        </a>
                        <a>
                            <button>
                                shop women
                            </button>
                        </a>
                    </div>
                </div>
            </section>


            <section className="Home-Section-3 bg-image-fifth">
                <div className="Home-Section-3-main-con">
                    <div className="Home-Section-3-deatils-con">
                        <h3>1 Five X FORTNITE</h3>
                        <h4>Limited collab.</h4>
                    </div>
                    <div className="Home-Section-3-btn-con">
                        <a>
                            <button>
                                shop the collab
                            </button>
                        </a>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeProduct