import React, { useEffect, useState } from 'react'
import { FcCancel } from "react-icons/fc";
import { useCartContext } from '../context/addToCart';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { get } from '../services/ApiRouting';
const FailedPage = () => {

    const { cartValue, setCartCount, amountDetails, setCartValue } = useCartContext();
    const profileData = JSON.parse(localStorage.getItem('profile'));
    console.log("profileData", profileData)
    const { order_id } = useParams();
    console.log("order_id", order_id)
    // const removeCartData = (index) => {
    //     const prevData = cartValue;
    //     prevData.splice(index, 1);
    //     addtoLocatStorage(prevData);
    //     setCartCount(prevData.length);
    // };

    useEffect(() => {
        // setCartCount(0);
        // setCartValue([]);
        // localStorage.setItem("cart", JSON.stringify([]));
    }, [])

    // =================== add to local Storage ==================

    // const addtoLocatStorage = (data) => {
    //     localStorage.setItem("cart", JSON.stringify(data));
    // };


    const [orderProduct, setOrderProduct] = useState(null);
    console.log("orderProduct", orderProduct)
    const fetchData = async () => {
        try {
            const response = await get(`/orders.php?action=fetch&order_id=${order_id}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1&user_id=${profileData.user_id}`);
            setOrderProduct(response.list[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // setCartCount(0);
        // setCartValue([]);
        // localStorage.setItem("cart", JSON.stringify([]));
        // localStorage.clear()
    }, [order_id]);


    return (
        <>
            <section className='SuccessPage-Section-con'>
                <div className='container'>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <FcCancel />
                                    <h2>Order Failed</h2>
                                    <p>We regret to inform you that there has been an issue with your order at Juite Pie, and it could not be successfully confirmed. We sincerely apologize for any inconvenience this may have caused. Our team is actively working to address the issue and will provide further assistance shortly.</p>
                                </div>
                            </div>
                            <div className='Order-Success-Details-con-Main'>
                                <div className='Order-Success-Details-con-Main-head'>
                                    <div className='Order-Success-List-head'>
                                        <h3>Order Id : - <span>{orderProduct && orderProduct.order_id}</span></h3>
                                    </div>
                                    <div className='Order-Success-List-head'>
                                        <h3>Ordered on : - <span>{orderProduct && moment(orderProduct.products.created_date).format("llll")}</span></h3>
                                    </div>
                                </div>
                            </div>


                            <div className='Order-Success-List-con'>
                                <div class="Order-Success-List">
                                    <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                        {
                                            orderProduct?.products?.map((elem) => {

                                                return (
                                                    <>
                                                        <div className='Product-addedCart-Side-details'>
                                                            <div className='Product-addedCart-Image-con'>
                                                                <div className='Product-addedCart-Image'>
                                                                    <img src={elem.image} />
                                                                </div>
                                                            </div>
                                                            <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                <div className='Product-details-addedCart-price order-successpage-product-details'>
                                                                    <h2>{elem.product_name}</h2>
                                                                    <h3><span>Color : </span>{elem.color_name}</h3>
                                                                    <h3><span>Size : </span>{elem.product_size}</h3>
                                                                    <h3><span>Quantity  : </span>{elem.product_qty}</h3>
                                                                </div>
                                                                <h4>{elem.product_price}/- Rs</h4>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='Product-details-Addedcart-total-con'>
                                        <div className='Product-details-Addedcart-total order-successpage-product-detail-final'>
                                            <div className='Product-details-Addedcart-total-title'>
                                                <h4>Total:</h4>
                                            </div>
                                            <div className='Product-details-Addedcart-total-Amount'>
                                                <h5>{orderProduct && orderProduct.amount} /- Rs</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order-success-page-btn-con'>
                                        <Link to="/">
                                            <button>
                                                Back to home
                                            </button>
                                        </Link>
                                        <Link to="/men-product">
                                            <button>
                                                Continu Shopping
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default FailedPage