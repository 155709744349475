import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import { get } from "../../services/ApiRouting";

const AddressSelect = ({ setFinalAddress }) => {
  const profileData = JSON.parse(localStorage.getItem('profile'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const showModal = async () => {
    setIsModalOpen(true);
    try {
      const response = await get(`/address.php?action=fetch&limit=10&page=1&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profileData.user_id}`);
      if (response && response.list) {
        setAddressList(response.list);
      } else {
        message.error('Failed to fetch addresses.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch addresses.');
    }
  };

  const handleOk = async () => {
    if (selectedAddress) {
      console.log("Selected address:", selectedAddress); // Ensure this is logged
      await updateAddress(selectedAddress);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const updateAddress = async (address) => {
    console.log("Updating address:", address); // Log the address to verify
    const formData = new FormData();
    formData.append('admin_token', "OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH");
    formData.append('action', 'update');
    formData.append('address_id', address.address_id);
    formData.append('user_id', profileData.user_id);
    formData.append('current_active', "1");

    try {
      const response = await fetch('https://skdm.in/server/onefiveapi/address.php', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Data received:', data);
        if (data.status == "Update successful") {
          setFinalAddress(address);  // Set the selected address object
          message.success('Address updated successfully.');
        } else {
          message.error('Failed to update address.');
        }
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      message.error('Failed to update address.');
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Select Shipping Address
      </Button>
      <Modal
        title="Select Address"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="OrderDetials-billing-personal-detailsOrderAddresscon">
          {addressList && addressList.map((elem, index) => (
            <div key={elem.address_id || index} className="OrderAddresscon-personal-details">
              <input
                type="radio"
                name="address"
                id={"id" + elem.address_id}  // Use address_id if it's unique
                className="form-input-check"
                onClick={() => handleAddressChange(elem)}
                checked={elem.current_active == "1"}  // Compare current_active to check the radio button
              />
              <label htmlFor={"id" + elem.address_id}>  {/* Use elem.address_id for htmlFor */}
                {elem.address},<br />
                {elem.city}, {elem.pincode},<br />
                {elem.state},<br />
                Phone Number: {elem.alternative_number}
              </label>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AddressSelect;
