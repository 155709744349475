import React from 'react';
import "../../Css/OrderDetails.css";
import { setProfile } from "../../services/localStorageServices";

const OrderDeatils = () => {

    // Retrieve data from localStorage
    const storedData = JSON.parse(localStorage.getItem('profile'));



    return (
        <>
            <div className='OrderDetials-con'>
                <div className='OrderDetials-billing-personal-details-con'>
                    <div className='OrderDetials-billing-personal-details'>
                        <h2>Personal Details</h2>
                        <div className='OrderDetials-personal-details'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='OrderDetials-personal-details-name'>
                                        <h2>{storedData.username}</h2>
                                        {/* <h2>Siddhesh</h2> */}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='OrderDetials-personal-details-name'>
                                        <h2>{storedData.email}</h2>
                                        {/* <h2>Siddhesh@gmail.com</h2> */}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='OrderDetials-personal-details-name'>
                                        <h2>{storedData.phone}</h2>
                                        {/* <h2>+91 7666754194</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDeatils