import React, { useEffect, useState } from 'react';
import "../../Css/OrderAddress.css";
import { GoPlus } from "react-icons/go";
import { Link } from 'react-router-dom';
import AddressModal from '../ModalCom/AddressModal';
import AddressSelect from '../modal/AddressSelect';
import { getdeliveryaddress } from '../../services/localStorageServices';
import axios from 'axios';
import { get } from '../../services/ApiRouting';

const OrderAddress = ({ selectedAddress, setSelectedAddress, allAddressData, setAlladdressData }) => {
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    console.log("selectedAddress", selectedAddress)
    const addressShowModal = () => {
        setIsAddressModalOpen(true);
    };

    const addressHandleCancel = () => {
        setIsAddressModalOpen(false);
    };

    return (
        <>
            <div className='OrderDetials-con OrderAddresscon'>
                <div className='OrderDetials-billing-personal-details-con'>
                    <div className='OrderDetials-billing-personal-details'>
                        <h2>Shipping Details</h2>
                        <div className='OrderAddresscon-personal-deatails'>
                            {/* Display selected address */}
                            {selectedAddress ?
                                <div className='OrderAddresscon-personal-deatails-selected'>
                                    <p>
                                        {selectedAddress.address},<br />
                                        {selectedAddress.city}, {selectedAddress.pincode},<br />
                                        {selectedAddress.state},<br />
                                        Phone Number: {selectedAddress.alternative_number}
                                    </p>
                                </div>
                                : <h5>No Address added</h5>}
                            {/* Pass the callback function to update selected address */}
                            {
                                selectedAddress ?
                                    <>
                                        <AddressSelect
                                        />
                                        <AddressModal
                                            addressShowModal={addressShowModal}
                                            isAddressModalOpen={isAddressModalOpen}
                                            addressHandleOk={addressHandleCancel}
                                            addressHandleCancel={addressHandleCancel}
                                            selectedAddress={selectedAddress}
                                            setSelectedAddress={setSelectedAddress}
                                            allAddressData={allAddressData}
                                            setAlladdressData={setAlladdressData}
                                        />
                                    </>
                                    :
                                    <AddressModal
                                        isAddressModalOpen={isAddressModalOpen}
                                        addressShowModal={addressShowModal}
                                        addressHandleOk={addressHandleCancel}
                                        addressHandleCancel={addressHandleCancel}
                                        setSelectedAddress={setSelectedAddress}

                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderAddress;
