import { IoStar } from "react-icons/io5";
import { IoIosStarOutline } from "react-icons/io";
import Product01 from "../../Images/Product/womenproduct/demoproduct1.webp";
import Product02 from "../../Images/Product/womenproduct/productimage11.webp";
import Product03 from "../../Images/Product/womenproduct/womenproduct2.webp";
import Product04 from "../../Images/Product/womenproduct/womenproduct21.webp";
import Product05 from "../../Images/Product/womenproduct/womenproduct3.webp";
import Product06 from "../../Images/Product/womenproduct/womenproduct31.webp";
import Product07 from "../../Images/Product/womenproduct/Producimage4.webp";
import Product08 from "../../Images/Product/womenproduct/Producimage41.webp";
import Product09 from "../../Images/Product/womenproduct/productimage5.webp";
import Product10 from "../../Images/Product/womenproduct/productimage51.webp";
import Product11 from "../../Images/Product/womenproduct/productimage6.webp";
import Product12 from "../../Images/Product/womenproduct/productimage61.webp";
import Product13 from "../../Images/Product/womenproduct/womenproduct7.webp";
import Product14 from "../../Images/Product/womenproduct/womenproduct71.webp";
import Product15 from "../../Images/Product/womenproduct/womenproduct8.webp";
import Product16 from "../../Images/Product/womenproduct/womenproduct81.webp";
const WomenData = [
    {
        category_Id: "1",
        slugs: "1-Five-Signature-Dress",
        product_Code: "1 Five Signature Dress",
        product_Desc: "Eco-friendly stylish bag ideal for traveling, shopping, and office items like lunch box/laptop/water bottle. Can also be used for gifting purposes. It comes with an inner cloth lining with a titch button. No machine wash, spot clean to get rid of any stain. 100% Natural Jute & Cotton rope Dual color, Titch button, no zipper, Jute & White. ",
        product_Id: "1",
        product_Name: "1 Five Signature Dress",
        action: "1",
        delivery_charges: "",
        product_type: "women",
        created_at: "2024-05-02 11:33:59",
        color: [
            {
                color_id: "1",
                product_Id: "1",
                color_code: "#808080",
                color_name: "gray",
                product_price: "10000",
                product_qty: "10",
                size: [
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XS",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "S",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "M",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "L",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XL",
                    },
                ],
                image: [
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product01,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product02,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product03,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product04,
                        product_image_type: "new",
                    },
                ]
            },
            {
                color_id: "1",
                product_Id: "1",
                color_code: "#ffffff",
                color_name: "white",
                product_price: "100",
                product_qty: "10",
                size: [
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XS",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "S",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "M",
                    },
                ],
                image: [
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product05,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product06,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product07,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        product_image: Product08,
                        product_image_type: "new",
                    },
                ]
            },
        ],
    }
];

export default WomenData