import React, { useLayoutEffect } from 'react';
import "../Css/ReturnPolicy.css";
import Footer from '../Component/FooterComponent/Footer';


const ReturnPolicy = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='return-policy-section'>
                <div className='container'>
                    <div className='retrun-policy-details-con'>
                        <div className='return-policy-details-head'>
                            <h2>Return Policy</h2>
                            <h3>Return Policy</h3>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>For BHARAT ( INDIA ) Orders Only :</h4>
                            <p>1 FIVE offers returns on most merchandise for Bharat 🇮🇳 customers within 14 days of the delivery date. </p>
                            <p>If 14 days have gone by since you have received your purchase, unfortunately we can’t offer you a refund or exchange. </p>
                            <p>
                                Please note that there is a restocking fee of 1000 ₹ that will be deducted from your order.
                                To be eligible for a return, the product must be unused and in the same condition that you received it. It must also be in the original packaging and the tag must be attached. Please don't forget every return must show complete picture of the conditions (brand new) and folded the clothes accordingly, and show clothing tag, barcode & ziplock bag intact.
                                Several types of goods are exempt from being returned. Perishable goods and personal care items i.e. soaps, other cosmetics and face masks cannot be returned. We also do not accept returns of products that are intimate, sanitary goods or hazardous materials i.e. condoms. We also do not accept returns or refund for digital products or e-gift cards.
                            </p>
                            <p>
                                To return your product, please fill the form here and someone from our support service will review and get back to you shortly with a return label if your claim is approved. For any other questions, feel free to contact our support at <a>support@1Five.in</a> and they will assist you with your return. Please always state the reason of your return in your email and your order number.
                                Once your return is prepared, you can drop it at your local post location. All returns are shipped back to our warehouse in Mumbai. Please allow 1-2 weeks for your return to arrive at our warehouse. Delivery dates may vary depending on where you are shipping your return from.
                                Once your return is delivered, please allow 3-7 business days for us to inspect and process the return. Once your return is processed, you will receive an email notification confirming your refund. Please allow 3-5 business days for the refund to show on your original form of payment.
                                Once an item has been exchanged, it is no longer eligible for return. All exchanges are considered final. Please ensure that the exchanged item meets your requirements before completing the exchange process.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>International Returns - </h4>
                            <p>1 FIVE accepts International returns. All fees are the responsibility of the customer.
                                To return your product, please fill the form here and someone from our support service will review and get back to you shortly with an unpaid return label if your claim is approved.</p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Domestic Exchanges ( BHARAT Only ) </h4>
                            <p>
                                1 FIVE accepts International returns. All fees are the responsibility of the customer.
                                To return your product, please fill the form here and someone from our support service will review and get back to you shortly with an unpaid return label if your claim is approved.
                            </p>
                            <p>
                                To initiate an exchange, <a>please click here.</a>
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>International Exchanges</h4>
                            <p>
                                1 FIVE does not offer exchanges on International orders.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Questions?</h4>
                            <p>
                                If you have any questions or concerns, please contact us at <a>support@1FIVE.in</a>
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Refunds</h4>
                            <p>
                                Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
                                If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Late or missing refunds</h4>
                            <p>
                                Once you have received an email from us confirming your refund, please allow 2-7 business days for the money to reach your account.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Missing Items</h4>
                            <p>
                                First, please check the package carefully,In most cases, the items might still be in the box. Before you get worried that some items are missing from your parcel, make sure you have thoroughly checked the packing materials. In some cases, some items are shipped separately and you would receive different tracking numbers if it is the case.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Second, contact us</h4>
                            <p>
                                If a product is missing, we can help. Please e-mail photos of the shipping label and the box with the items received, a brief description of the situation, the order number to <a>support@1FIVE.in</a> We will follow-up with next steps as soon as possible.
                            </p>
                            <p>
                                You can tell us about your missing item within 14 days. If 14 days have gone by since you have received your purchase, unfortunately we can’t offer you a refund or reship.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Lost Packages</h4>
                            <p>
                                We are not liable for any misplaced or stolen packages. If your tracking number reads as successfully shipped and the address provided to us upon checkout matches the exact address we have shipped your order to, we are not held responsible if your package has been misplaced. Stolen / lost packages are nonrefundable. If you have any questions, please contact us at <a>support@1FIVE.in</a> and we will try our best to assist you with this matter.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Can I cancel/amend my order after I've placed it?</h4>
                            <p>
                                If your order is placed on a week day, our warehouse is really quick at packing your order up which means we can't make any changes or cancel it once you've placed it. This includes changing the delivery option, delivery address or payment method.
                            </p>
                            <p>
                                If your order is placed on a weekend or a bank holiday, you may be able to cancel your order and place a new one instead if informed within 24 hours of placing your order.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ReturnPolicy