import { useLayoutEffect } from "react";
import Footer from "../Component/FooterComponent/Footer";

const TermsConditions = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='return-policy-section'>
                <div className='container'>
                    <div className='retrun-policy-details-con'>
                        <div className='return-policy-details-head'>
                            <h2>Terms & Conditions</h2>
                            <h3>Terms & Conditions</h3>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p> We value the trust you place in 1Five. That's why we insist upon your reading these Terms of Use carefully. The Terms of Use and policies about Privacy, Fees & Payments, Promotions, Delivery, Returns, and Refund together shall form the entire Terms of Use.</p>
                            <p>The Agreement, inter alia, provides the terms that govern your access to use (i) 1Five’s website www.1Five.in and its mobile and tablet applications (“Platforms”), (iii) 1Five’s online lifestyle and fashion merchandise (“Products”) through the Platforms, and (iv) the purchase of Products, and any other service that may be provided by 1Five from time to time (collectively referred to as the “Services”).</p>
                            <p>You hereby understand and agree that the Agreement forms a binding contract between 1Five and anyone who accesses, browses, or purchases the Products and uses the Services in any manner (“User”) and accordingly, you hereby agree to be bound by the terms contained in the Agreement. If you do not agree to the terms contained in the Agreement, you are advised not to proceed with purchasing the Products or using the Services. The terms contained in the Agreement shall be accepted without modification. The use of the Services would constitute acceptance of the terms of the Agreement.</p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>Terms and Conditions Applicable to Users</h4>
                            <p>Users must be 18 years of age or older to register, or visit or use the Services in any manner. By registering, visiting or using the Services, you hereby represent and warrant to 1Five that you are 18 years of age or older, and that you have the right, authority and capacity to use the Services, and agree to abide by the Agreement. If a User is below 18 years of age, it is assumed that he/she is using/browsing the Platforms under the supervision of his/her parent or legal guardian and that such User’s parent or legal guardian has read and agrees to the terms of this Agreement, including terms of purchase of Products, on behalf of the minor User. Should 1Five be made aware that a User is under the age of 18 and is using/browsing the Platforms without the supervision of his/her parent or legal guardian, 1Five reserves the right to deactivate such User’s account without further notice.</p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <h4>The Agreement is governed by the provisions of Indian law, including, but not limited to:</h4>
                            <p>the Indian Contract Act, 1872;</p>
                            <p>the Information Technology Act, 2000;</p>
                            <p>the rules, regulations, guidelines and clarifications framed thereunder, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”) and</p>
                            <p>the Information Technology (Intermediaries Guidelines) Rules, 2011 (“IG Rules”).</p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                The contents of Services, information, text, graphics, images, logos, button icons, software code, interface, design and the collection, arrangement and assembly of the content on the Platforms or any of the other Services are the property of 1Five, its parent company, group companies, subsidiaries, associates, affiliates, suppliers, vendors and sister companies, as the case may be (“1Five Content”), and are protected under copyright, trademark and other applicable laws. You shall not modify the 1Five Content or reproduce, display, publicly perform, distribute, reverse engineer or otherwise use the 1Five Content in any way for any public or commercial purpose or for personal gain.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                1Five authorises you to view and access the 1Five Content solely for identifying Products, carrying out purchases of Products and processing returns and refunds, in accordance with Return and Refund Policy, if any. 1Five, therefore, grants you a limited, revocable permission to access and use the Services. This permission does not include a permission for carrying out any resale of the Products or commercial use of the 1Five Content, any collection and use of product listings, description, or prices, and, any derivative use of the Platforms or of 1Five Content.                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                As means to assist the Users in identifying the Products of their choice, 1Five provides visual representations on the Platforms including graphics, illustrations, photographs, images, videos, charts, screenshots, infographics and other visual aids. While reasonable efforts are made to provide accurate visual representations, 1Five disclaims any guarantee or warranty of exactness of such visual representation or description of the Product, with the actual Product ultimately delivered to Users. The appearance of the Product when delivered may vary for various reasons.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                Users may make purchases on the Platforms. For the purposes of identifying a User, 1Five may, from time to time, collect certain personally identifiable information such as your first name and last name, email address, mobile phone number, postal address, other contact information, demographic profile, etc. Users may also register themselves on the Platforms. Registration on the Platforms is one-time and you are required to remember your username and password and keep the same confidential. In the event where you have misplaced your username and password details, you can retrieve and change the same using the “forgot username/password” option on the Platforms.                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                The User shall assume all risks, liabilities, and consequences if his/her account has been accessed illegally or without authorisation through means such as hacking and if through such unauthorised access, a purchase of Products has been made through the Services. It is specifically clarified that payments of monies towards any Products purchased through the Services by unauthorised or illegal use of the User’s account shall entirely be borne by the User.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                Display of Products for purchase on the Platforms is merely an invitation to offer. An order placed by a User for purchase of a Product constitutes an offer. All orders placed by Users on the Platforms are subject to the availability of such Product, 1Five’s acceptance of the User’s offer and the User’s continued adherence to the terms of the Agreement.
                            </p>
                            <p>
                                You agree to maintain and promptly update all data provided by you and to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current, incomplete, or if 1Five has reasonable grounds to suspect that the information provided by you is untrue, inaccurate, not current, incomplete, or not in accordance with the terms of the Agreement, 1Five reserves the right to indefinitely suspend, terminate or block your access to the Platforms, and refuse to provide you with access to the Platforms in future.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                All rights and liabilities of 1Five with respect to any Services to be provided by it shall be restricted to the scope of the Agreement. In addition to the Agreement, you shall also ensure that you are in compliance with the terms and conditions of the third parties, whose links are contained/embedded in the Services. It is hereby clarified that 1Five shall not be held liable for any transaction between you and any such third parties.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                You understand that on your registration as a User or on your purchase of Products on the Platforms, you may receive text messages and/or emails from 1Five on your registered mobile number and/or email address. These messages and/or emails could relate inter alia to your registration, 1Five’s acceptance or rejection of your offer to purchase a Product, payment information, Product despatch information, information pertaining to other activities you carry out on the Platforms and information pertaining to the promotions that are undertaken by 1Five (or third parties in connection with the Platforms) from time to time. It is specifically clarified that a text message and/or an email confirming the receipt of your order is not an acceptance from 1Five that the Product will be delivered. 1Five’s acceptance to your offer to purchase shall occur and conclude only when the Products have been despatched by 1Five and a text message and/or email confirming such despatch has been sent to you. 1Five, at all times, reserves the right to limit the quantity of items in, or cancel an order prior to despatch.
                            </p>
                            <p>
                                Any communication from 1Five shall be sent only to your registered mobile number and/or email address or such other contact number or email address that you may designate, for any particular transaction. You shall be solely responsible to update your registered mobile number and/or email address on the Platforms in the event there is a change. Further, 1Five may also send you notifications and reminders with respect to scheduled deliveries of the purchased Products. While 1Five shall make every endeavour to share prompt reminders and notifications relating to the delivery of purchased Products with you, 1Five shall not be held liable for any failure to send such notifications or reminders to you.
                            </p>
                        </div>
                        <div className='retrun-policy-details-for-bhtart'>
                            <p>
                                1Five may, at any time and without having to service any prior notice to you: (i) upgrade, update, change, modify, or improve the Services or a part of the Services in a manner it may deem fit, and (ii) change the contents of the Terms of Use in substance, or as to procedure or otherwise; in each case which will be applicable to all Users. You hereby agree that this is in the fairness of things given the nature of the business and its operations and you will abide by them. As such, you must keep yourself updated at all times and review the terms of the Agreement from time to time. Such changes shall be made applicable when they are posted. 1Five may also alter or remove any content from the Platforms without notice.
                            </p>
                            <p>
                                While 1Five shall make reasonable endeavours to maintain high standards of security and shall provide the Services by using reasonable efforts, 1Five shall not be liable for any interruption that may be caused to your access or use of the Services.
                            </p>
                            <p>
                                Access to and registration on the Platforms is free of cost. Although unlikely, 1Five may modify the Fee, Payment and Promotions Policy to include a fee on access and browsing of the Platforms, or for use of any new service introduced by 1Five without serving prior notice on the Users.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
};

export default TermsConditions;