import React, { useLayoutEffect } from 'react';
import "../Css/AboutUs.css";
import Footer from "../Component/FooterComponent/Footer";

const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <>

      {/* section 1  */}
      <section className='about-page-banner-section'>
        <div className='about-page-banner-con'>
          <h2>Our Story</h2>
        </div>
      </section>

      {/* section 2 */}

      <section className='about-page-section-2'>
        <div className='about-page-section-2-main-con'>
          <div className='about-page-section-2-details'>
            <h3>About Us</h3>
            <p>1 FIVE - Luxury Redefined is a premier lifestyle brand specializing in apparel and accessories, boasting a massive following and customer base across social media platforms worldwide.</p>
            <p>Founded in 2024 by Bollywood actor Sunil Kumar, the brand is distinguished by its 'All Shades of Black' collections, featuring clothing, accessories, and leather goods for both men and women.</p>
            <p>We aim to innovate with our visual content and merchandise, promoting a lifestyle that emphasizes quality and design. The 'All Black Lifestyle' embodies a cultural transformation, inspiring individuals to live authentically and free from superficiality. As traditional fashion trends fade, it's time to elevate your life with 1 FIVE.</p>
          </div>
          <div className='about-page-section-2-image-con'>
          </div>
        </div>
      </section>

      {/* section 3 */}

      <section className='about-page-section-3'>
        <div className='about-page-section-3-details-con'>
          <h3>Get In Touch</h3>
          <h4>For Media Enquiries</h4>
          <h5>For any media or public relations questions, please contact our team at: Team@1FIVE.in mail to <a>Team@1FIVE.in</a></h5>

        </div>
      </section>

      {/* section 4 */}

      <section className='about-page-section-2'>
        <div className='about-page-section-2-main-con'>
          <div className='about-page-section-2-details'>
            <h2>FEATURED POST</h2>
            {/* <div className='about-page-section-4-tagline'>
              <h3>1 Five has just opened in San Jose, California!</h3>
            </div> */}
            <p>1 FIVE is excited to announce that we are planning to open a new store in Mumbai, India, in the next few months.</p>
          </div>
          <div className='about-page-section-4-image-con'>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AboutUs