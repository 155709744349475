import React, { useLayoutEffect } from 'react';
import "../Css/Shipping.css";
import Footer from "../Component/FooterComponent/Footer";
const Shipping = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='shipping-section'>
                <div className='container'>
                    <div className='shipping-section-con'>
                        <div className='shipping-section-head-con'>
                            <h2>Shipping Information</h2>
                            <h3>Fast Shipping</h3>
                            <p>Shipments are fulfilled as soon as your order is verified, it may take up to 72 hours to process and ship your order during business days (Monday - Friday).</p>
                            <p>After your order has been shipped, delivery time is 2-7 business days. Please keep in mind that during holidays or limited edition launches, this time may vary. </p>
                            <p>To ensure the best delivery service, we ship using the top carriers (DHL, Fedex, Bluedart ) You will be informed about the expected delivery date during checkout. Please note that shipments are only processed on week days.</p>
                            <p>Additionally, we will send you an email the moment your shipment is handed over to shipping provider (typically within 1-3 business days). They will provide you with a tracking number so you can follow your shipment throughout its journey.</p>
                        </div>
                        <div className='shipping-section-head-con'>
                            <h2>Information about customs</h2>
                            <p>Depending on your country, import/customs fees may be applied to your order.</p>
                            <p>All applicable custom fees, taxes and duties are the sole responsibility of the customer and refused packages won't be refunded. Please note that we are not held liable for any custom fees in your country, hold times or incorrect shipping information provided. Allow 1 to 2 business days of processing your order to receive your tracking number. </p>
                            <p>1Five is not responsible for any shipping deliveries that may be affected by customs, natural occurrences, or air and ground transportation strikes or delays, nor any extra fees, customs or back end charges. </p>
                        </div>
                        <div className='shipping-section-head-con'>
                            <h2>Lost Package</h2>
                            <p>We are not liable for any misplaced or stolen packages. If your tracking number reads as successfully shipped and the address provided to us upon checkout matches the exact address we have shipped your order to, we are not held responsible if your package has been misplaced.</p>
                            <p>Stolen / lost packages are nonrefundable. If you have any questions, please contact us at support@1FIVE.in and we will try our best to assist you with this matter.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Shipping