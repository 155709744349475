import React, { useEffect, useState } from 'react';
import "../Css/SuccessPage.css";
import { PiSealCheckFill } from "react-icons/pi";
// import ProductData from '../Component/ProductData';
import { useCartContext } from '../context/addToCart';
// import EmptyComp from ".././Component/Empty/empty";
import { Link, useParams } from 'react-router-dom';
import deliverymanriding from "../Images/delivery-man-riding.png";
import { get } from '../services/ApiRouting';
import moment from 'moment';
const SuccessPage = () => {

    const { cartValue, setCartCount, amountDetails, setCartValue } = useCartContext();
    const profileData = JSON.parse(localStorage.getItem('profile'));
    console.log("profileData", profileData)
    const { order_id } = useParams();
    console.log("order_id", order_id)
    // const removeCartData = (index) => {
    //     const prevData = cartValue;
    //     prevData.splice(index, 1);
    //     addtoLocatStorage(prevData);
    //     setCartCount(prevData.length);
    // };

    useEffect(() => {
        // setCartCount(0);
        // setCartValue([]);
        // localStorage.setItem("cart", JSON.stringify([]));
    }, [])

    // =================== add to local Storage ==================

    // const addtoLocatStorage = (data) => {
    //     localStorage.setItem("cart", JSON.stringify(data));
    // };


    const [orderProduct, setOrderProduct] = useState(null);
    console.log("orderProduct", orderProduct)
    const fetchData = async () => {
        try {
            const response = await get(`/orders.php?action=fetch&order_id=${order_id}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1&user_id=${profileData.user_id}`);
            setOrderProduct(response.list[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // setCartCount(0);
        // setCartValue([]);
        // localStorage.setItem("cart", JSON.stringify([]));
        // localStorage.clear()
    }, [order_id]);

    return (
        <>
            <section className='SuccessPage-Section-con'>
                <div className='container'>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <PiSealCheckFill />
                                    <h2>Order Confirmed</h2>
                                    <p>Thank you for shopping with 1Five! We're excited to let you know that your order has been successfully confirmed. Our team is hard at work preparing your items with the utmost care. We appreciate your trust in us and can't wait for you to enjoy your purchase. Thanks for choosing 1Five, where quality and satisfaction are our top priorities.</p>
                                </div>
                                <div className='Order-Success-Details-con-Main'>
                                    <div className='Order-Success-Details-con-Main-head'>
                                        <div className='Order-Success-List-head'>
                                            <h3>Order Id : - <span>{orderProduct && orderProduct.order_id}</span></h3>
                                            {/* <h3>Order Id : - <span>1028271910192838</span></h3> */}
                                        </div>
                                        <div className='Order-Success-List-head'>
                                            <h3>Ordered on : - <span>{orderProduct && moment(orderProduct.products.created_date).format("llll")}</span></h3>
                                            {/* <h3>Ordered on : - <span>15-05-2024</span></h3> */}
                                        </div>
                                    </div>
                                    <div className='Order-Success-Details-con'>
                                        <div className='Order-Success-Details-add'>
                                            <h1>delivering to :</h1>
                                            <div className='Order-Success-Details-name-contact'>
                                                <h2>
                                                    {orderProduct && orderProduct.user_name}
                                                </h2>
                                                <span>
                                                    |
                                                </span>
                                                <h2>
                                                    {orderProduct && orderProduct.user_phone}
                                                </h2>
                                            </div>
                                            <div className='Order-Success-Details-address'>
                                                <p>{orderProduct && orderProduct.address}, {orderProduct && orderProduct.city}, {orderProduct && orderProduct.state}, {orderProduct && orderProduct.pincode}</p>
                                                {/* <p>Office No 301, MOHID HEIGHTS, opposite Mahada, signal, near kokilaben hospital, Suresh Nagar,  Andheri West, Mumbai, Maharashtra 400053,</p> */}
                                            </div>
                                        </div>
                                        <div className='Order-Success-Details-Image-con'>
                                            <img src={deliverymanriding} alt="" />
                                        </div>
                                    </div>
                                </div>


                                <div className='Order-Success-List-con'>
                                    <div class="Order-Success-List">
                                        <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                            {/* {
                                                orderProduct && orderProduct.products.map((elem, index) => {
                                                    console.log("first", elem)
                                                    const { size, image, color } = elem
                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details'>
                                                                <div className='Product-addedCart-Image-con'>
                                                                    <div className='Product-addedCart-Image'>
                                                                        <img src={image[0].product_image} />
                                                                    </div>
                                                                </div>
                                                                <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                    <div className='Product-details-addedCart-price'>
                                                                        <h2>{elem.Product_Name}</h2>
                                                                        <h3><span>Color : </span>{elem.color_name}</h3>
                                                                        <h3><span>Size : </span>{elem.Product_Height} x {elem.Product_Width}</h3>
                                                                    </div>
                                                                    <h4>{elem.product_price}/- Rs</h4>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            } */}
                                            {
                                                orderProduct?.products?.map((elem) => {

                                                    return (
                                                        <>
                                                            <div className='Product-addedCart-Side-details'>
                                                                <div className='Product-addedCart-Image-con'>
                                                                    <div className='Product-addedCart-Image'>
                                                                        <img src={elem.image} />
                                                                    </div>
                                                                </div>
                                                                <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                    <div className='Product-details-addedCart-price order-successpage-product-details'>
                                                                        <h2>{elem.product_name}</h2>
                                                                        <h3><span>Color : </span>{elem.color_name}</h3>
                                                                        <h3><span>Size : </span>{elem.product_size}</h3>
                                                                        <h3><span>Quantity  : </span>{elem.product_qty}</h3>
                                                                    </div>
                                                                    <h4>{elem.product_price}/- Rs</h4>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='Product-details-Addedcart-total-con'>
                                            <div className='Product-details-Addedcart-total order-successpage-product-detail-final'>
                                                <div className='Product-details-Addedcart-total-title'>
                                                    <h4>Total:</h4>
                                                </div>
                                                <div className='Product-details-Addedcart-total-Amount'>
                                                    <h5>{orderProduct && orderProduct.amount} /- Rs</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-success-page-btn-con'>
                                            <Link to="/">
                                                <button>
                                                    Back to home
                                                </button>
                                            </Link>
                                            <Link to="/men-product">
                                                <button>
                                                    Continue Shopping
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessPage