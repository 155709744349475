import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import "../ModalCom/profileModal.css";

const ProfileModal = ({ isModalOpen, handleOk, handleCancel }) => {
    const [form] = Form.useForm();
    const [profileImage, setProfileImage] = useState(null); // State for profile image file
    const profileData = JSON.parse(localStorage.getItem('profile'));
    const [fileList, setFileList] = useState([])
    useEffect(() => {
        if (profileData) {
            form.setFieldsValue({
                name: profileData.username.split(' ')[0],
                last: profileData.username.split(' ')[1],
                email: profileData.email,
                contact: profileData.phone,
            });
            setProfileImage(null); // Reset profile image state when profile data changes
        }
    }, [profileData, form]);

    const onFinish = (values) => {
        // Update localStorage storage with new values
        const updatedProfile = {
            username: `${values.name} ${values.last}`,
            email: values.email,
            phone: values.contact,
            image: profileImage ? URL.createObjectURL(fileList) : null, // Store image URL for display
        };
        localStorage.setItem('profile', JSON.stringify(updatedProfile));
        handleOk(); // Close modal or perform other actions on successful form submission
    };

    const onImageChange = info => {
        const file = info.file.originFileObj;
        setFileList(info.fileList);

        setProfileImage(file); // Set the new profile image file
    };

    const validateImage = (_, value) => {
        if (!value && !profileImage) {
            return Promise.reject(new Error('Please select a profile image.'));
        }
        return Promise.resolve();
    };

    return (
        <Modal title="Edit profile" visible={isModalOpen} onOk={form.submit} onCancel={handleCancel} width={1000}>
            <div className='profile-modal-main-con'>
                <Form layout='vertical' form={form} onFinish={onFinish}>
                    <div className='profile-modal-name-and-surname-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Item name="name" label="First Name" rules={[{ required: true, message: 'Please enter your first name.' }]}>
                                    <Input placeholder='First Name' />
                                </Form.Item>
                            </div>
                            <div className='col-md-6'>
                                <Form.Item name="last" label="Last Name" rules={[{ required: true, message: 'Please enter your last name.' }]}>
                                    <Input placeholder='Last Name' />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address.' }]}>
                            <Input placeholder='Enter Email' />
                        </Form.Item>
                        <Form.Item name="contact" label="Phone Number" rules={[{ required: true, message: 'Please enter your phone number.' }]}>
                            <Input placeholder='Enter Contact Number' />
                        </Form.Item>
                        <Form.Item name="profileImage" label="Profile Image" rules={[{ validator: validateImage }]}>
                            <Upload
                                name="profileImage"
                                listType="picture"
                                onChange={onImageChange}
                                fileList={fileList}
                                beforeUpload={() => false} // Prevent file upload on selection, handle it in onChange
                            >
                                {fileList.length >=1 ? null :
                                <Button icon={<UploadOutlined />}>Select Image</Button>}
                            </Upload>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ProfileModal;
