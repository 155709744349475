import React from 'react';
import "../FooterComponent/Footer.css";
import Logo from "../../Images/Logo/New Footer Logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import whatsapp from "../../Images/whatsapp.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
    return (
        <>

            <section className='footer-section-1'>
                <div className='footer-section-1-main-con'>
                    <div className='footer-section-logo-con'>
                        <Link to="/">
                            <div className='footer-section-logo'>
                                <img src={Logo} alt='Logo' />
                            </div>
                        </Link>
                    </div>

                    <div className='footer-section-details-con'>
                        <div className='row footer-rows'>
                            <div className='col-md-4 footer-col'>
                                <div className='footer-section-address-info'>
                                    <div className='footer-section-address-info-head'>
                                        <h3 className='head-style'>Contact Details</h3>
                                    </div>
                                    <a href='https://maps.app.goo.gl/3qigVhZseWTBwL2t5' target='blank'>
                                        <p>1FIVE - Apparel & Accessories
                                            Shop No.20, Ground Floor,
                                            Citi Mall, New Link Road, Andheri - West, Mumbai, Maharashtra, Pin : 400053</p>
                                    </a>
                                    <a href="https://wa.me/+919892331133?text=" target='blank'>
                                        <p>+91 9892-331-133</p>
                                    </a>
                                    <a href="mailto: eyelifehospital@gmail.com" target='blank'>
                                        <p>1FiveOriginal@gmail.com</p>
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-2 footer-col'>
                                <div className='footer-section-menu-info-con'>
                                    <div className='footer-section-address-info-head'>
                                        <h3 className='head-style'>INFORMATION</h3>
                                    </div>
                                    <div className='footer-section-menu-info'>
                                        <Link to="/return-policy">
                                            Returns
                                        </Link>
                                        <Link to="/shipping-policy">
                                            Shipping
                                        </Link>
                                        <Link to="/privacy-policies">
                                            Privacy
                                        </Link>
                                        <Link to="/terms-conditions">
                                            Terms & Conditions
                                        </Link>
                                        <Link to="/contact">
                                            Contact
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 footer-col'>
                                <div className='footer-section-menu-info-con'>
                                    <div className='footer-section-address-info-head'>
                                        <h3 className='head-style'>MORE</h3>
                                    </div>
                                    <div className='footer-section-menu-info'>
                                        <Link to="/">
                                            Home
                                        </Link>
                                        <Link to="/about">
                                            About
                                        </Link>
                                        <Link to="/men-product">
                                            Men
                                        </Link>
                                        {/* <Link to="/women-product">
                                            Women
                                        </Link> */}
                                        <Link to="/contact">
                                            Accessories
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 footer-col'>
                                <div className='footer-section-menu-info-con'>
                                    <div className='footer-section-address-info-head'>
                                        <h3 className='head-style'>MORE</h3>
                                    </div>
                                    <div className='Footer-Section-social-media'>
                                        <h4>Stay connected.</h4>
                                        <div className='Footer-Section-social-media-main-con'>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href='https://www.instagram.com/1fiveindia/?igsh=aW5mdXRvZG1kMWcy' target='blank'><FaInstagram /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href='https://www.facebook.com/people/1-FIVE/61558017640379/?mibextid=LQQJ4d' target='blank'><FaFacebookSquare /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href='https://x.com/1fiveindia?s=21&t=abNZb9BXQdbo-Hy7hi12Xg' target='blank'><FaXTwitter /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href='https://www.youtube.com/@1fiveindia' target='blank'><FaYoutube /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href="mailto: 1FiveOriginal@gmail.com" target='blank'><IoMailOutline /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href="https://wa.me/+919892331133?text=" target='blank'><FaWhatsapp /></a>
                                            </div>
                                            <div className='Footer-Section-social-media-con'>
                                                <a href="tel:+919892331133" target='blank'><IoIosCall /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer